import * as THREE from "three"
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper.js'




THREE.ColorManagement.enabled = false

/**
 * Base
 */
// Debug
const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()











/**
 * Lights
 */

//Ambient Light
const ambientLight = new THREE.AmbientLight(0xffffff, 0.15 )
scene.add(ambientLight)

//Directional Light
const directionalLight = new THREE.DirectionalLight(0x00fffc, 0.3)
directionalLight.position.set(0, 2, 0)
scene.add(directionalLight)


//Hemisphere Light
const hemisphereLight = new THREE.HemisphereLight(0xff0000, 0x0000ff, 0.4)
scene.add(hemisphereLight)


//Point Light
const pointLight = new THREE.PointLight(0xff9000, 1, 3, 2)
pointLight.position.set(1, 1.5, 1)
scene.add(pointLight)


//React Area Light
const rectAreaLight = new THREE.RectAreaLight(0x4e00ff, 2, 1, 1)
rectAreaLight.position.set(- 1.5, 1.6, 1)
rectAreaLight.lookAt(new THREE.Vector3())
scene.add(rectAreaLight)


//Spot Light
const spotLight = new THREE.SpotLight(0x78ff00, 0.5, 6, Math.PI * 0.1, 0.25, 1)
spotLight.position.set(4, 2, 3)
scene.add(spotLight)

spotLight.target.position.x = -0.5
scene.add(spotLight.target)

/**
 * Helpers

const hemisphereLightHelper = new THREE.HemisphereLightHelper(hemisphereLight, 0.2) 
scene.add(hemisphereLightHelper)


const directionalLightHelper = new THREE.DirectionalLightHelper(directionalLight, 0.2)
scene.add(directionalLightHelper)

const pointLightHelper = new THREE.PointLightHelper(pointLight, 0.2)
scene.add(pointLightHelper)

const spotLightHelper = new THREE.SpotLightHelper(spotLight, 0.2)
scene.add(spotLightHelper)

window.requestAnimationFrame(() => 
{
    spotLightHelper.update()
})

const rectAreaLightHelper = new RectAreaLightHelper(rectAreaLight)
scene.add(rectAreaLightHelper)

 */




/**
 * TEXTURES
 */
const textureLoader = new THREE.TextureLoader()
const particleTexture = textureLoader.load('/textures/particles/8.png')


/**
 * Particles
 */
// Geometry
const particlesGeometry = new THREE.BufferGeometry()
const count = 5000

const positions =  new Float32Array(count * 3)


for(let i=0; i<count*3; i++)
{
    positions[i] = (Math.random() - 0.5) * 10
}

particlesGeometry.setAttribute(
    'position',
    new THREE.BufferAttribute(positions, 3)
    )



//Material
const particlesMaterial = new THREE.PointsMaterial()
particlesMaterial.color = new THREE.Color('#ff88cc')
particlesMaterial.size = 0.1
particlesMaterial.sizeAttenuation = true
particlesMaterial.map = particleTexture
particlesMaterial.depthWrite = false
particlesMaterial.transparent = true
particlesMaterial.alphaMap = particleTexture
particlesMaterial.alphaTest = 0.001

//Points
const particles = new THREE.Points(particlesGeometry, particlesMaterial)
scene.add(particles)






/**
 * Objects
 */
// Material
const material = new THREE.MeshNormalMaterial()
material.roughness = 0.4
 



// Objects

//SPHERE

const sphere = new THREE.Mesh(
    new THREE.SphereGeometry(0.65, 36, 36),
    material
)
sphere.position.x = - 1.5




//CUBE 

const cube = new THREE.Mesh(
    new THREE.BoxGeometry(0.85, 0.85, 0.85),
    material
)



//TORUS 

const torus = new THREE.Mesh(
    new THREE.TorusGeometry(0.4, 0.24, 48, 72),
    material
)
torus.position.x = 1.5



//PLANE

const plane = new THREE.Mesh(
    new THREE.PlaneGeometry(4,4),
    new THREE.MeshStandardMaterial({ color:'#000000' })
)

plane.rotation.x = - Math.PI * 0.5
plane.position.y = - 8

//Plane 2
const plane2 = new THREE.Mesh(
    new THREE.PlaneGeometry(8,8),
    material
)

plane2.rotation.x = - Math.PI * 0.5
plane2.position.y = -10

scene.add(sphere, cube, torus, plane, plane2)


//Debug
//Torus
gui
    .add(torus.position, 'y')
    .min(-3)
    .max(3)
    .step(0.01)
    .name('Donut position:')

gui 
    .add(sphere, 'visible')
    .min(-3)
    .max(3)
    .step(0.01)
    .name('Sphere Show:')

gui 
    .add(material, 'wireframe' )
    .name('Wireframe:')











/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})





/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 1.5
camera.position.y = 2.5
camera.position.z = 2.5
scene.add(camera)




// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true


/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.outputColorSpace = THREE.LinearSRGBColorSpace
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()


    //Update Plane
    plane.rotation.z = elapsedTime * 0.2
    //Update Particles
    particles.rotation.y = elapsedTime * 0.12


    // Update objects
    sphere.position.y = Math.cos(elapsedTime)
    cube.rotation.y = 1 * elapsedTime
    torus.rotation.y = 0.7 * elapsedTime

    sphere.position.z = Math.sin(elapsedTime)
    cube.rotation.x = 0.15 * elapsedTime
    cube.position.z = -0.15 * elapsedTime
    torus.rotation.x = 0.3 * elapsedTime

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()